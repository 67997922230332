import React from "react";

import DefaultLayout from "../../../../layouts/default";

const DocHDownloads = () => (
  <DefaultLayout title="Downloads – WiCare|Doc-H">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/wicaredoc-color.svg"
                alt="WiCare|Doc Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|Doc-H</span>
              <span className="line">
                <span className="is-visible-desktop">
                  &nbsp;Patientendokumentation
                </span>
                &nbsp;für Akut-Betriebe
              </span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-h/">Übersicht</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-h/technik/">
                Technik
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-doc-h/referenzen/">
                Referenzen
              </a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-doc-h/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-small-bottom">
            <h2>WiCare|Doc Dokumente</h2>
          </section>
          <section>
            <div className="dlFlex has-padding-top has-padding-bottom">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doc-h.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc H – die elektronische Patientendokumentation
                </h5>
                <p className="boldFliesstext">
                  WiCare|Doc ist die professionelle Patientendokumentation, für
                  die individuelle Planung, Dokumentation und Beurteilung der
                  auf den Menschen ausgerichteten Behandlung. Durch den Einsatz
                  von WiCare|Doc wird jeder Aspekt des pflegerischen Handelns
                  verbessert. Das bedeutet auch eine qualitative Verbesserung
                  der pflegerischen Haltung. WiCare|Doc unterstützt den gesamten
                  Pflegeprozess durch gezielte Vereinfachungen und gewährleistet
                  gleichzeitig einen schnellen Überblick über alle Aktivitäten
                  der verschiedenen Berufsgruppen.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-h/2022_WCD-H_screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-bottom has-padding-top">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/doctacsthumb.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc + WiCare|tacs für Pflege UND Therapie am SPZ
                </h5>
                <p className="boldFliesstext">
                  In Nottwil liegt die grösste von vier Spezialkliniken für
                  Para- und Tetraplegiker in der Schweiz. Die drei übrigen
                  befinden sich in Basel, Sion und Zürich. Das SPZ erbringt
                  heute rund zwei Drittel aller Leistungen für
                  Querschnittgelähmte in der Schweiz und betreut als
                  Privatklinik, dank der Unterstützung der Schweizer
                  Paraplegiker-Stiftung, auch allgemein versicherte Patienten.
                  Das Paraplegiker-Zentrum vereint in hohem Masse Pflege und
                  Therapie. Seit 2012 arbeitet die Pflege erfolgreich mit den
                  Softwarelösungen WiCare|Doc und WiCare|tacs von WigaSoft, die
                  Therapie und Rehabilitation seit Ende 2013.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-h/Story_WCDuWCT_SPZ_screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-bottom has-padding-top">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/zukunftthumb.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc – der Blick in die Zukunft ist Alltag
                </h5>
                <p className="boldFliesstext">
                  clinicum-Artikel, Ausgabe 4-11.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-h/WS_clincium_042011_screen_Web.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-bottom has-padding-top">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/oberengadinthumb.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  WiCare|Doc – im Spital Oberengadin erfolgreich eingeführt
                </h5>
                <p className="boldFliesstext">
                  Die Patientendokumentation WiCare|Doc wurde im Spital
                  Oberengadin Samedan erfolgreich eingführt und abgenommen.
                  Unter dem Titel "Pflege neuster Stand - Pflegealltag im
                  digitalen Zeitalter" haben wir, gemeinsam mit dem Spital,
                  einen Erfolgs-Bericht verfasst.
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/doc-h/WCD_Story_Spital_Oberengadin_screen.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
            <hr className="blackHR" />
            <div className="dlFlex has-padding-bottom has-padding-top">
              <div>
                <img
                  className="dlimg"
                  src="/assets/images/download/buechbergthumb.png"
                />
              </div>
              <div>
                <h5 className="boldTitle">
                  CarePflege im Alterszentrum am Buechberg in Fislisbach
                </h5>
                <p className="boldFliesstext">
                  Alterszentrum «Am Buechberg»: mehr Transparenz, Effizienz und
                  persönliche Note IT ausgebaut – Qualität und Prozesse
                  verbessert
                  <i>
                    Artikel aus der Fachzeitschrift Clinicum - Ausgabe 4.2013
                  </i>
                </p>
                <a
                  target="_blank"
                  href="/assets/dokumente/c09.2013_CarePflege_Fislisbach.pdf"
                >
                  <button className="large notThatLarge smallStaticMargin-top">
                    &nbsp;&nbsp;&nbsp;Download&nbsp;&nbsp;&nbsp;
                  </button>
                </a>
              </div>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-h/">
                    WiCare|Doc-H
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-doc-h/downloads/">
                    Downloads
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>
                      -L BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>
                      -L RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>
                      -B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>
                      -H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>
                      -R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2023 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>

              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default DocHDownloads;
